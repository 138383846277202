
import { Route, Routes } from 'react-router-dom';
import TestOrder from './pages/TestOrder';
import Games from './pages/Games';
import CreateBuild from './pages/CreateBuild';

function App() {

  return (
    <>
    <Routes>
      <Route path="/" element={ <CreateBuild/> } />
      <Route path="/games" element={ <Games/> } />
    </Routes>
    </>
    
  )
}

export default App;
