import { Button, Card } from "@material-tailwind/react";
import axios from "axios";
import Papa from "papaparse";
import React, { useState, useEffect } from "react";

type App = {
  name: string;
  logo: string;
  cpu: number;
  gpu: number; 
  ram: number;
};

type DisplayApp = {
  data: App;
  selected: boolean;
}

function Games() {
  
  const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

  const handleClick = async () => {
    try {
      const response = await axios.post(`${apiURL}/submit`, {
        apps: apps,
      }
    );
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (err) {
      console.error('Error creating checkout session:', err);
    }
  };

  const [data, setData] = useState<App[]>([]);
  const [apps, setApps] = useState<DisplayApp[]>([])

  useEffect(() => {
    const fetchCsvData = async () => {
      const response = await fetch('/games.csv');
      const reader = response.body?.getReader();
      const result = await reader?.read();
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result?.value); 
      const results = Papa.parse(csv, { header: true });

      const normalisedData = results.data.map((item: any) => ({
        name: item.Name,
        logo: item.Logo,
        cpu: Number(item.CPU),
        gpu: Number(item.GPU),
        ram: Number(item.RAM),
      }));

      setData(normalisedData as unknown as App[]);
      
      let _apps: DisplayApp[] = []
      normalisedData.forEach(app => {
        _apps.push({data: app as unknown as App, selected: false})
      })
      setApps(_apps);
    };

    fetchCsvData();
  }, []);

  return (
    <div>
      <div className="flex flex-row">
        <h1 className="text-black text-2xl mb-8">Games</h1>
        <Button className="ml-auto mb-8" onClick={handleClick}>Submit</Button>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {apps.map((app, index) => (
          <li key={index}>
             <div 
              className={`select-none flex flex-col justify-center items-center ${app.selected ? "bg-white hover:bg-[#fafafa] border-[2px] border-blue-300" : "hover:bg-[#fafafa] bg-white border-[2px] border-primary"} rounded-2xl p-4 card-shadow cursor-pointer`}
              onClick={() => {
                const _apps = [...apps]
                // @ts-ignore
                _apps.find(tApp => (tApp.data.name === app.data.name)).selected = !app.selected;
                setApps(_apps);
              }}>
                  <img src={app.data.logo} alt={""} className=" h-24 pointer-events-none"/>
                  <div className="mt-4 pb-1 font-bold text-[18px]">{app.data.name}</div>
              </div>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default function AppPage() {

  return (
    <>
      <div className="m-8">
        <Games />
      </div>
    </>
  )
}