import { Card } from "@material-tailwind/react"
import { Case, CaseVariant } from "./pages/Case"

interface CaseCardProps {
    caseData: Case;
}

const CaseCard: React.FC<CaseCardProps> = ({caseData}) => {
    const variant: CaseVariant = caseData.variants[0];
    return (
        <Card className="w-[256px] flex flex-col items-center justify-center text-[#212121] cursor-pointer">
            <img src={variant.images[0]} width={170} className="mt-4"></img>
            <div className="font-bold text-[16px] mt-4 px-4 text-center">{caseData.name}</div>
            <div className="font-normal text-[12px] mb-4">${variant.price}</div>
        </Card>
    )
}

export default CaseCard;