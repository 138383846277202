import { Case, CaseVariant } from "./pages/Case";

interface CaseVariantProps {
    caseData: Case | undefined;
    onSelectVariant: (variant: CaseVariant) => void;
}

const CaseVariantSelector: React.FC<CaseVariantProps> = ({caseData, onSelectVariant}) => {
    

    if (!caseData)
        return <></>

    return (
        <div className="flex flex-col px-4 my-4">
            <div className="text-[24px] font-bold">Colour</div>
            <div className="flex flex-row gap-[10px]">
                {caseData.variants.map(variant => {
                    return (
                        <div key={Math.random()} className={`border-2 border-black w-12 h-12 rounded-full cursor-pointer hover:opacity-90`} style={{backgroundColor: variant.hex}} onClick={() => {
                            console.log("Check B");
                            onSelectVariant(variant);
                        }}>

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CaseVariantSelector;