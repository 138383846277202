import { Button, Card, IconButton } from "@material-tailwind/react";
import CaseCard from "../CaseCard";
import { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Papa from "papaparse";
import CaseVariantSelector from "../CaseVariant";
import card from "@material-tailwind/react/theme/components/card";

export type Case = {
    name: string,
    form: string,
    variants: CaseVariant[],
}

export type CaseVariant = {
    name: string,
    hex: string,
    price: number,
    images: string[],
}

type CaseProps = {
    onContinue: (c: Case | undefined, v: CaseVariant) => void;
}

const CasePage: React.FC<CaseProps> = ({onContinue}) => {
    const [cases, setCases] = useState<Case[]>();

    const [selectedImage, setSelectedImage] = useState<string>("");

    const [selectedCase, setSelectedCase] = useState<Case | undefined>(undefined);
    const [variant, setVariant] = useState<CaseVariant>();

    const [isMobile, setIsMobile] = useState<boolean>(false);

    const titleRef = useRef<HTMLDivElement>(null);
    const [titleHeight, setTitleHeight] = useState<number>(-1);

    const cardRef = useRef<HTMLDivElement>(null);
    const [cardHeight, setCardHeight] = useState<number>(450);

    useEffect(() => {
        if (cardRef.current) {
            const height = cardRef.current.offsetHeight;
            
            setCardHeight(height - titleHeight);
        }
    }, [variant, titleHeight]);

    useEffect(() => {
        if (titleRef.current) {
            const height = titleRef.current.offsetHeight;
            setTitleHeight(height);
            console.log(height);
        }
    }, [variant]);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1140);
    };
    
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (cases && !isMobile)
            setSelectedCase(cases[0])
    }, [cases, isMobile])

    useEffect(() => {
        const fetchCsvData = async () => {
          const response = await fetch('/cases.csv');
          const reader = response.body?.getReader();
          const result = await reader?.read();
          const decoder = new TextDecoder('utf-8');
          const csv = decoder.decode(result?.value); 
          const results = Papa.parse(csv, { header: true });
            
          const _cases: Case[] = [];

          // results.data.forEach((item: any) => {
          results.data.forEach((item: any) => {
            const data = JSON.parse(item.JSON);
            
            const variants: CaseVariant[] = Object.keys(data).map(key => {
                const newData = data[key];

                return (
                    {
                        name: key,
                        hex: (newData["hex"]),
                        price: Number(newData["price"]),
                        images: (newData["images"] as string[]),
                    }
                )
            })

            _cases.push({
                name: item.Case,
                form: item.Form,
                variants: variants,
            })
          });

          setCases(_cases);
        };
    
        fetchCsvData();
      }, []);

      useEffect(() => {
        if (selectedCase) {
            setVariant(selectedCase.variants[0])
        }
      }, [selectedCase])

      useEffect(() => {
        if (variant)
            setSelectedImage(variant.images[0]);
      }, [variant])

      const onSelectVariant = (variant: CaseVariant) => {
        setVariant(variant);
      }

    return (
        <div className="flex flex-col w-full mt-4 text-[#212121]">
            {!selectedImage &&
                <div className="flex flex-col mb-4">
                    <div className="font-semibold text-[36px]">Case</div>
                    <div className="font-normal text-[24px]">Select your case</div>
                </div>
            }
            {(isMobile && !selectedCase) ?
                <div className="flex w-full items-center justify-center">
                    <div className="flex flex-col gap-8 ">
                        {cases &&
                            <>
                                {cases.map(c => {
                                    return (
                                        <div onClick={() => {
                                            setSelectedCase(c);
                                            window.scrollTo(0, 0);
                                        }}>
                                            <CaseCard caseData={c} />
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>
                :
                <div></div>
            }
            <div className={`flex flex-row gap-16 h-full ${selectedCase ? 'mt-4 xl:mt-16' : ''}`}>
                {(!isMobile) && 
                    <div className="h-screen w-[400px]">
                        <div className="flex flex-col gap-8 overflow-y-auto h-[70%] ">
                            {cases &&
                                <>
                                    {cases.map(c => {
                                        return (
                                            <div key={c.name} onClick={() => {
                                                setSelectedCase(c);
                                                window.scrollTo(0, 0);
                                            }}>
                                                <CaseCard caseData={c} />
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                }
                {variant &&
                <Card className={`text-[#212121] mb-16 h-full pb-4 w-full relative ${isMobile && !selectedCase ? 'hidden' : ''}`}>
                    <div className="xl:flex xl:flex-row">
                        <div className="flex flex-row">
                            <IconButton className="ml-4 mt-2.5 xl:hidden" variant="text">
                                <ArrowLeftIcon className="mt-1 xl:mt-0 h-6 stroke-[#21211] stroke-2 mb-[2px]" onClick={() => {setSelectedCase(undefined)}}/>
                            </IconButton>
                            <div className="flex flex-col" ref={cardRef}>
                                <div className="px-4 text-[40px] font-bold" ref={titleRef}>{selectedCase?.name}</div>
                                <div className=" text-[15px] font-normal mt-4 px-4 hidden xl:block mr-auto">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. 
                                </div>
                                <div className="hidden xl:block mt-auto">
                                    <CaseVariantSelector caseData={selectedCase} onSelectVariant={onSelectVariant}/>
                                    <Button size="lg" className="mx-4 flex flex-row justify-center" onClick={() => onContinue(selectedCase, variant)}>
                                        Continue
                                        <ArrowRightIcon className="h-4 stroke-white stroke-2 mb-[2px]"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col xl:items-center xl:flex-row-reverse ml-auto">
                            <div className="flex justify-center mt-4 pl-4 max-h-[400px] min-w-[400px]">
                                <img src={selectedImage} className="rounded-lg max-h-[400px]" alt="Selected Case" />
                            </div>
                            {/*Image Scroller*/}
                            <Card className="w-full mt-4 max-h-[100px] xl:max-h-none xl:w-[100px] xl:overflow-hidden " style={{height: 450}}>
                                <div className={`flex flex-row xl:flex-col gap-[10px] overflow-y-auto xl:overflow-x-auto no-scrollbar`} >
                                    {variant.images.map((img, index) => (
                                        <img 
                                            key={index}
                                            src={img} 
                                            alt="" 
                                            height={100} 
                                            className={`rounded-lg cursor-pointer max-w-[100px] max-h-[100px]`} 
                                            onClick={() => setSelectedImage(img)}
                                        />
                                    ))}
                                </div>
                            </Card>
                        </div>
                        {/*Variants*/}
                        <div className="xl:hidden">
                            <CaseVariantSelector caseData={selectedCase} onSelectVariant={onSelectVariant}/>
                        </div>
                        <div className="text-[15px] font-normal mt-4 px-4 xl:hidden">
                            North reimagines the gaming PC, introducing natural materials and bespoke details to make gaming a stylish addition to the living space. Fusing design and airflow engineering, the case features fine-patterned mesh ventilation and an open front with real walnut or oak panels. 
                            <br/><br/>
                            The design is complemented by sleek brass or steel details and an integrated tab for easy access to the top of the case. Inside, North offers an intuitive interior layout and generous compatibility.
                        </div>
                        <Button size="lg" className="mx-4 mb-4 flex flex-row justify-center mt-4 xl:hidden" onClick={() => onContinue(selectedCase, variant)}>
                            Continue
                            <ArrowRightIcon className="h-4 stroke-white stroke-2 mb-[2px]"/>
                        </Button>
                    </div>
                </Card>
                }
                
            </div>
        </div>
    );
}

export default CasePage;
